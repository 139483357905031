<template>
  <form>
    <div
      slot="title"
      class="text-xl flex"
    >
      <span>
        <i
          class="el-icon-arrow-left text-lg mr-2 cursor-pointer hover:text-blue align-top"
          @click="prevSlide"
        />
      </span>
      <div>
        <p class="text-xs text-grey">
          {{ $t('user_management.modules_list[0]') }}
        </p>
        <p class="mt-2 text-sm text-black">
          {{ name }}
        </p>
      </div>
    </div>

    <div class="pt-5 flex">
      <div class="flex-1">
        <el-tree
          ref="tree1"
          :data="columnModules[0]"
          :props="treeProps"
          show-checkbox
          :default-expanded-keys="getExpandedKeys(columnModules[0], checkedModules)"
          :default-checked-keys="getCheckedKeys(columnModules[0], checkedModules)"
          node-key="id"
          @check-change="handleCheckChange"
          :check-on-click-node="true"
          :expand-on-click-node="false"
        >
          <span
            slot-scope="{ node, data }"
            class="custom-tree-node text-xs"
          >
            <span
              v-if="node.disabled"
              class="text-grey"
            >{{ node.label }}</span>
            <span v-else>{{ node.label }}</span>
            <span
              v-if="data.isParent && node.disabled"
              class="text-grey"
            />
          </span>
        </el-tree>
      </div>
      <div class="flex-1">
        <el-tree
          ref="tree2"
          :data="columnModules[1]"
          :props="treeProps"
          :default-expanded-keys="getExpandedKeys(columnModules[1], checkedModules)"
          :default-checked-keys="getCheckedKeys(columnModules[1], checkedModules)"
          node-key="id"
          show-checkbox
          :check-on-click-node="true"
          :expand-on-click-node="false"
        >
          <span
            slot-scope="{ node, data }"
            class="custom-tree-node text-xs"
          >
            <span
              v-if="node.disabled"
              class="text-grey"
            >{{ node.label }}</span>
            <span v-else>{{ node.label }}</span>
            <span
              v-if="data.isParent && node.disabled"
              class="text-grey"
            />
          </span>
        </el-tree>
      </div>
    </div>

    <!-- Buttons -->
    <div class="mt-5 flex justify-end">
      <el-button
        class="mr-2"
        size="small"
        @click="closeParentModal"
      >
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        type="primary"
        :disabled="disableButton"
        size="small"
        @click="applyChanges"
      >
        {{ $t('actions.apply_changes') }}
      </el-button>
    </div>
  </form>
</template>

<script>

import _ from 'lodash';

export default {
  name: 'ModuleList',

  props: {
    name: {
      type: String,
      default: '',
    },

    allRoles: {
      type: Array,
      default: () => [],
    },

    userRoles: {
      type: Array,
      default: () => [],
    },

    accountUser: {
      type: Object,
      default: () => ({}),
    },

    prettifyLabel: {
      type: Function,
      default: () => {},
    },

    apply: {
      type: Function,
      default: () => {},
    },

    isInvite: {
      type: Boolean,
      default: () => {},
    },
  },

  data() {
    return {
      disableButton: false,
      checkedModules: [],
      treeProps: {
        children: 'children',
        label: 'label',
        disabled: 'disabled',
      },
    };
  },

  computed: {
    columnModules() {
      const {
        SmsToSurveyEnabled: hasSmsToSurvey,
        Flag_UrlShorten: flagUrlShorten,
        IsPostPaid: isPostPaid,
        Product_SMS: hasSMS,
        Product_CA: hasCA,
        Product_VO: hasVO,
        Product_VI: hasVI,
        Product_AT: hasAT,
        BetaFeatures: betaFeatures,
        // AccessLevel: access,
        Roles: roles,
      } = this.accountUser;

      let modules = [];

      let allModules = this.allRoles.slice(0);

      allModules = allModules.filter(v => v !== 'UserManagement_v2');

      // // exclude user management for only admin and user with manageUSER role can access user management page
      // if (access === 'U' && roles.includes('UserManagement.ManageUsers_v2')) {
      //   allModules = allModules.filter(v => !v.match(/UserManagement/i));
      // }

      // check if account has sms survey
      if (!hasSmsToSurvey) {
        allModules = allModules.filter(v => !v.match(/SMS.SmsEngage/i));
      }

      // check if account has url shortening
      if (!flagUrlShorten) {
        allModules = allModules.filter(v => !v.match(/SMS.ShortUrls/i));
      }

      // check if account is postpaid
      if (isPostPaid) {
        allModules = allModules.filter(v => !v.match(/Payment/i));
      }

      if (!roles.includes('SMS.ShowMessageBody_v2')) {
        allModules = allModules.filter(v => !v.match(/CA.ShowMessageBody/i));
      }

      const { noSubmodules, withSubmodules, withSubmodulesName } = allModules.reduce((a, b) => {
        // if module matched e.g SMS.Reports ...
        if (b.match(/([\w]+\.)+[\w]+(?=[\s]|$)/)) {
          const [parent] = b.split('.');
          a.withSubmodules.push(b);
          a.withSubmodulesName.push(parent);
        } else {
          a.noSubmodules.push(b);
        }

        return a;
      }, { noSubmodules: [], withSubmodules: [], withSubmodulesName: [] });

      const ws = _.uniq(withSubmodulesName).map((v) => {
        let disabled = false;

        // disabled selection if account has not been enabled for that specific product
        if (!hasSMS && v.match(/SMS/i)) {
          disabled = true;
        }

        if (!hasCA && v.match(/CA/i)) {
          disabled = true;
        }

        if (!hasVI && v.match(/VI/i)) {
          disabled = true;
        }

        if (!hasVO && v.match(/VO/i)) {
          disabled = true;
        }

        return {
          id: v,
          label: this.prettifyLabel(v),
          name: v,
          children: [],
          disabled,
          isParent: true,
        };
      });

      const ns = noSubmodules.map(v => ({
        id: v,
        label: this.prettifyLabel(v),
        name: v,
        isParent: true,
        disabled: v.match(/Automation_v2/i) && !hasAT,
      }));

      withSubmodules.forEach((v) => {
        ws.map((n) => {
          if (v.startsWith(`${n.name}.`)) {
            // eslint-disable-next-line no-unused-vars
            const [parent, child] = v.split('.');
            const { disabled } = n;

            n.children.push({
              id: v,
              label: this.prettifyLabel(child),
              name: v,
              disabled,
              isParent: false,
            });
          }

          return n;
        });
      });

      modules = modules.concat(ws).concat(ns);

      // // Split array into 2 colums
      const col1 = modules.slice(0, 3);
      const col2 = modules.slice(3, modules.length);
      return [col1, col2];
    },
  },

  created() {
    this.checkedModules = this.userRoles;
  },

  methods: {
    // Go back to previous slide
    prevSlide() {
      this.resetKeys();
      this.$emit('backEvent');
    },

    resetKeys() {
      this.$refs.tree1.setCheckedKeys([]);
      this.$refs.tree2.setCheckedKeys([]);
      this.checkedModules = [];
    },

    handleCheck() {
      // do nothing for now
    },

    handleCheckChange(node, isChecked) {
      this.handleCampaignNodes(node, isChecked);
      this.handleUserNodes(node, isChecked);
    },

    handleCampaignNodes(node, isChecked) {
      const needsApprovalNode = this.$refs.tree1.getNode('SMS.NeedsApproval_v2');
      const campaignApproverNode = this.$refs.tree1.getNode('SMS.CampaignApprover_v2');

      if (node.id === 'SMS.CampaignApprover_v2' && isChecked && needsApprovalNode.checked) {
        this.$refs.tree1.setChecked(needsApprovalNode.key, false, true);
      }

      if (node.id === 'SMS.NeedsApproval_v2' && isChecked && campaignApproverNode.checked) {
        this.$refs.tree1.setChecked(campaignApproverNode.key, false, true);
      }
    },

    handleUserNodes(node, isChecked) {
      // const manageUsersNode = this.$refs.tree1.getNode('UserManagement.ManageUsers_v2');
      const viewUsersNode = this.$refs.tree1.getNode('UserManagement.ViewUsers_v2');

      if (node.id === 'UserManagement.ManageUsers_v2' && isChecked && !viewUsersNode.checked) {
        this.$refs.tree1.setChecked(viewUsersNode.key, true, true);
      }
    },

    getExpandedKeys(cols, modules) {
      if (!modules.length) {
        return [];
      }

      let keys = [];

      // get column modules having children and not disabled
      const wc = cols.slice(0)
        .filter(v => !v.disabled && v.children && v.children.length);

      if (wc.length) {
        keys = wc.map(v => v.id);
      }

      return keys;
    },

    getCheckedKeys(cols, modules) {
      if (!modules.length) {
        return [];
      }

      const keys = [];

      cols.forEach((v) => {
        if (!v.children && modules.includes(v.name)) {
          keys.push(v.id);
        }

        if (v.children) {
          v.children.forEach((c) => {
            if (modules.includes(c.name)) {
              keys.push(c.id);
            }
          });
        }
      });

      return keys;
    },

    // Close modal and discard changes
    closeParentModal() {
      this.resetKeys();
      this.$emit('closeModal');
    },

    // Apply selected modules
    applyChanges() {
      const col1 = this.$refs.tree1.getCheckedKeys();
      const col2 = this.$refs.tree2.getCheckedKeys();

      const modules = [].concat(col1).concat(col2);

      if (!modules.length) {
        this.$message.error(this.$t('user_management_permission[8]'));
        return;
      }

      // props func should be used to avoid inheritance issue
      this.apply(modules, this.name);

      this.resetKeys();
      this.$emit('backEvent');
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
  }
</style>
