//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import singleSpa from 'single-spa';

import {
  mapGetters, mapActions, mapState, mapMutations,
} from 'vuex';


import UsersTable from './partials/Table.vue';
import UserModal from './UserModal.vue';


export default {
  name: 'Users',

  components: { UsersTable, UserModal },

  data() {
    const self = this;

    return {
      modalState: {},
      loading: false,
      userAuthData: {},
      actionLoading: false,
      statuses: [{
        status: 'A',
        name: self.$t('statuses.active'),
        type: 'success',
      }, {
        status: 'I',
        name: self.$t('statuses.invited'),
        type: 'warning',
      }, {
        status: 'B',
        name: self.$t('statuses.deactivated'),
        type: 'info',
      }],

      userStatus: 'A',

      searchInput: '',
      isMoreFilterVisible: false,
      enforce2fa: false,
      isImpersonation: Boolean(localStorage.getItem('cpv3Impersonate')),
      countedFilters: ['status'],
      defaultFilters: {
        status: '',
      },
    };
  },

  computed: {
    ...mapState('common', ['auth']),
    ...mapGetters('usermanagement', [
      'getUsers',
      'getFilteredUsers',
      'getPageNo',
      'getPageSize',
      'getSearch',
      'getFilters',
      'getPaginated',
      'getLoading',
    ]),
    ...mapGetters('saml', ['getConfig']),

    filterCount() {
      const filters = this.countedFilters.reduce((a, b) => {
        if (Object.keys(this.defaultFilters).some(f => f === b)) {
          // eslint-disable-next-line no-param-reassign
          a.default[b] = this.defaultFilters[b];
          // eslint-disable-next-line no-param-reassign
          a.touched[b] = this.getFilters[b];
        }

        return a;
      }, { default: {}, touched: {} });

      const affectedFilters = Object.keys(filters.default).reduce((a, b) => {
        if (filters.default[b] !== filters.touched[b]) {
          a.push(b);
        }

        return a;
      }, []);


      return affectedFilters.length;
    },
  },

  watch: {
    getLoading() {},
  },

  created() {
    this.getUserData();
    this.defaultFilters = {
      status: this.userStatus,
    };
    // this is for checking user
    this.loading = true;
    Promise.all([
      this.getAuthUser(),
      this.getSubAccounts({ AccountUid: this.userAuthData.AccountUid }),
    ])
      .then(() => {
        this.userAuthData = this.auth.data;

        // Check the account config
        if (this.userAuthData.AccountConfig) {
          const accountConfig = JSON.parse(this.userAuthData.AccountConfig);
          this.enforce2fa = accountConfig.Enforce2FA;
        }

        if (this.userAuthData.AccessLevel === 'U'
          && !this.userAuthData.Roles.some(v => v.match(/UserManagement/i))) {
          throw new Error('Access Forbidden');
        }

        if (this.userAuthData.AccessLevel === 'A'
          || (this.userAuthData.AccessLevel === 'U' && (this.userAuthData.Roles.includes('UserManagement.ViewUsers_v2') || this.userAuthData.Roles.includes('UserManagement.ManageUsers_v2')))) {
          return this.getAllUsers();
        }

        return Promise.resolve();
      }).then(() => {
        if (this.userAuthData.AccessLevel === 'A'
          || (this.userAuthData.AccessLevel === 'U' && this.userAuthData.Roles.includes('UserManagement.ManageSSOSettings_v2'))) {
          return this.getSamlConfig();
        }
        return Promise.resolve();
      })
      .catch((err) => {
        if (err && err.message === 'Unauthorized') {
          this.$showError(this, err);
          localStorage.removeItem('WWW-Authenticate');
          localStorage.removeItem('cpv3Impersonate');
          localStorage.removeItem('CPV3_User');
          localStorage.removeItem('passwordResetSkipped');
          localStorage.removeItem('user_country');
          sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');
          // singleSpa.navigateToUrl('/login');
          //
        } if (err && err.message === 'Access Forbidden') {
          // this.$message.error('Access Forbidden!');
          this.$showError(this, err);
          singleSpa.navigateToUrl('/403');
        } else {
          this.$showError(this, err);
          // this.$message.error('Error occurred. Please refresh page.');
        }
      })
      .finally(() => {
        this.loading = false;
      });

    // this.getAllUsers()
    //   .catch((err) => {
    //     if (err && err.message === 'Unauthorized') {
    //       localStorage.removeItem('WWW-Authenticate');
    //       // singleSpa.navigateToUrl('/login');
    //       this.$showError(this, err);
    //     } else {
    //       this.$showError(this, err);
    //       // this.$message.error('Error occurred. Please refresh page.');
    //     }
    //   });
  },

  // mounted() {
  //   window.addEventListener('localeChanged', () => {
  //     this.$i18n.locale = this.$getSelectedLocale();
  //   });
  // },

  methods: {
    ...mapActions({
      getAllUsers: 'usermanagement/getUsers',
      getAuthUser: 'common/getAuthUser',
      getSamlConfig: 'saml/get',
      updateSamlConfig: 'saml/update',
      getSubAccounts: 'usermanagement/getSubAccounts',
      updateAccountConfig: 'usermanagement/updateAccountConfig',
    }),

    ...mapMutations({
      setFilters: 'usermanagement/SET_FILTERS',
      setSearch: 'usermanagement/SET_SEARCH',
      setFilteredUsers: 'usermanagement/SET_FILTERED_USERS',
      setPageNo: 'usermanagement/SET_PAGE_NO',
      setPageSize: 'usermanagement/SET_PAGE_SIZE',
      resetFilters: 'usermanagement/RESET_FILTERS',
    }),

    refresh() {
      this.getAllUsers(false);
    },
    clearFilters() {
      this.userStatus = 'A';
      this.searchInput = null;
      this.getAllUsers();
    },

    getUserData() {
      try {
        const user = localStorage.getItem('CPV3_User');

        this.userAuthData = JSON.parse(user) || {};
      } catch (e) {
      // do nothing
      }
    },

    disable() {
      this.$confirm(this.$t('user_management.index[7]'), this.$t('user_management.index[8]'), {
        confirmButtonText: this.$t('user_management.index[9]'),
        cancelButtonText: this.$t('actions.cancel'),
        confirmButtonClass: 'el-button--danger',
        type: 'warning',
      }).then(() => {
        const config = this.getConfig;
        this.updateData({ enabled: false, accountUid: config.AccountUid }, 'disable');
      }).catch(() => {

      });
    },

    enable() {
      const config = this.getConfig;
      this.updateData({ enabled: true, accountUid: config.AccountUid }, 'enable');
    },

    updateData(payload, action = '') {
      this.actionLoading = true;

      this.updateSamlConfig(payload).then(() => this.getSamlConfig()).then(() => {
        if (action) {
          const msg = action === 'enable' && payload.enabled
            ? this.$t('success.enabled', { value: this.$t('user.sso_sign_on') })
            : this.$t('success.disabled', { value: this.$t('user.sso_sign_on') });
          this.$notify.success({
            title: msg,
          });
        } else {
          this.$notify.success({
            title: this.$t('success.updated', { value: this.$t('user.sso_sign_on') }),
          });
        }
      }).catch((err) => {
        this.$showError(this, err);
      })
        .finally(() => {
          this.actionLoading = false;
        });
    },

    openUserModal(user) {
      const auth = this.userAuthData;
      this.openModal({ user, name: 'ModulePermission', auth });
    },

    openModal(modal) {
      this.modalState = {
        ...modal,
        visible: true,
      };
    },

    getStatus(s) {
      return this.statuses.find(v => v.status === s);
    },

    get2FaStatus(s) {
      const status = {};

      if (s === 'A') {
        status.name = 'App';
        status.icon = 'icon-novo-tile';
      }

      if (s === 'S') {
        status.name = 'SMS';
        status.icon = 'icon-novo-sms';
      }

      return status;
    },

    selectStatus(v) {
      this.setFilters({ status: v });
      this.setPageNo(1);
      this.setFilteredUsers();
    },

    searchUser: _.debounce(
      function search() {
        this.setSearch(this.searchInput);
        this.setPageNo(1);
        this.setFilteredUsers();
      }, 300,
    ),

    enable2fa(value) {
      this.$confirm(
        this.$t(value ? 'confirmations.enforce2fa' : 'confirmations.disable2fa'),
        `${this.$t('actions.enforce2fa')}`, {
          confirmButtonText: this.$t('actions.continue'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        },
      ).then(() => {
        let config = {};
        if (this.userAuthData.AccountConfig) {
          const parsedAccountConfig = JSON.parse(this.userAuthData.AccountConfig);
          config = parsedAccountConfig;
        }
        config.Enforce2FA = value;
        this.updateAccountConfig({
          accountUid: this.userAuthData.AccountUid,
          config: {
            Config: JSON.stringify(config),
          },
        }).then(() => {
          this.$message.success('Account config is updated');
        }).catch((err) => {
          // this.$message.error(`Oops, Failed to update account config`);
          this.enforce2fa = !this.enforce2fa;
          this.$showError(this, err);
        }).finally(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.enforce2fa = !this.enforce2fa;
      });
    },
  },
};
