
import _ from 'lodash';

const prettifyLabel = (string) => {
  if (!string) return '';

  let st = string
    .replace(/_v2/, '')
    .replace(/\./g, ' ')
    .replace(/CA/, 'ChatApps')
    .replace(/VI/, 'Video')
    .replace(/VO/, 'Voice');

  st = _.startCase(st);

  return st;
};

const concatModules = (modules) => {
  const m = modules.slice(0).map((v) => {
    const x = prettifyLabel(v);

    return x;
  });

  return m.join(', ');
};

export default {
  prettifyLabel,
  concatModules,
};
